<script lang="ts" setup>
const props = defineProps<{
  showNote: boolean
  notes?: string
}>()
</script>

<template>
  <div class="bg-pattern flex items-center justify-center py-8">
    <!-- The parent div stays in the DOM always.
         We'll toggle its opacity using a class.
         We do this not cause a layout shift on the toggle of the element -->
    <div
      class="bg-white p-1 transition-opacity duration-500 ease-in-out"
      :class="{ 'opacity-0': !props.showNote, 'opacity-100': props.showNote }"
    >
      <p class="max-w-4xl text-center text-7xl text-gray-900 font-semibold leading-none">
        {{ props.notes }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.bg-pattern {
  background: url('/dots_background.svg') repeat;
}
</style>
