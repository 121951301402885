<script lang="ts" setup>
defineOptions({
  name: 'AppContentPriceContainer',
})

const props = withDefaults(defineProps<{
  pricing: {
    label: string
    amount: number
    from?: boolean
  }[]
  compact?: boolean
}>(), {
  compact: false,
})
</script>

<template>
  <div
    class="mt-27 flex justify-stretch gap-13 bg-ppGray-900 px-15" :class="[
      {
        'py-25': !props.compact,
        'py-15': props.compact,
      },
    ]"
  >
    <!-- <div class="h-22 w-22 flex items-center justify-center rounded-full bg-white">
      <span class="text-15 text-ppGray-900 font-600 leading-none">P</span>
    </div> -->
    <div class="flex flex-1 flex-col gap-13">
      <p class="text-15 text-white font-600 leading-none">
        Parktarife
      </p>
      <div class="h-[3px] w-full bg-separatorGray" />
      <AppContentPriceItem v-for="(price, index) in props.pricing" :key="index + price.label" :label="price.label" :amount="price.amount" :from="price.from" />
    </div>
  </div>
</template>
