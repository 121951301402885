<script lang="ts" setup>
import { logInitApp } from '@/utils/logger'

defineOptions({
  name: 'DefaultLayout',
})

const route = useRoute()

logInitApp(route.fullPath)
</script>

<template>
  <div class="h-screen w-screen flex flex-col justify-between overflow-hidden">
    <AppHeader class="h-1/4 w-auto -mb-30" />
    <slot />
    <AppFooter class="flex-1" />
  </div>
</template>
