export const data = {
  areaName: 'Palais Vest',
  logo: '/palais_vest_no_white_gaps.avif',
  carImage: '/car_camera.svg',
  notes: 'Automatische Kennzeichenerkennung',
  pricing: [
    {
      amount: 1.5,
      label: 'Je angefangene Std.',
    },
    {
      amount: 12.00,
      label: 'Tageshöchstsatz',
    },
    {
      amount: 9.00,
      label: 'Tagesticket (nach Einfahrt zahlbar)',
    },
  ],
}
