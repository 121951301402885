import { createRouter, createWebHistory } from 'vue-router'
import PalaisVest from '@/views/PalaisVest.vue'
import GeraArcaden from '@/views/GeraArcaden.vue'
import HomeView from '@/views/Home.vue'
import WebsocketTest from '@/views/WebsocketTest.vue'
import PalaisVestDynamicPlate from '@/views/PalaisVestDynamicPlate.vue'
import PalaisVestExit from '@/views/PalaisVestExit.vue'
import { setSuperProperties } from '@/utils/logger'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/palais-vest',
      name: 'palais-vest',
      component: PalaisVest,
    },
    {
      path: '/palais-vest-dynamic-plate',
      name: 'palais-vest-dynamic-plate',
      component: PalaisVestDynamicPlate,
    },
    {
      path: '/palais-vest-exit',
      name: 'palais-vest-exit',
      component: PalaisVestExit,
    },
    {
      path: '/gera-arcaden',
      name: 'gera',
      component: GeraArcaden,
    },
    {
      path: '/websocket-test',
      name: 'websocket-test',
      component: WebsocketTest,
    },
  ],
})

router.beforeEach((to) => {
  setSuperProperties({
    full_path: to.fullPath,
    client_uuid: to.query.client_uuid as string ?? 'missing',
    route_name: to.name?.toString() ?? 'missing',

  })
})

export default router
