<script lang="ts" setup>
defineOptions({
  name: 'AppContentTitle',
})
const props = withDefaults(
  defineProps<{
    areaName: string
    logo?: string
    welcomeMessage?: string
    goodbyeMessage?: string
  }>(),
  {
    welcomeMessage: 'Willkommen im',
  },
)
</script>

<template>
  <div class="px-15">
    <img v-if="props.logo" :src="props.logo" class="h-30" alt="area-logo">

    <!-- Show welcome message if there's no goodbye message -->
    <h1 v-if="props.welcomeMessage && !props.goodbyeMessage" class="mt-15 text-25 text-ppGray-900 font-600 leading-none">
      {{ props.welcomeMessage }} <br> {{ props.areaName }}
    </h1>

    <!-- Goodbye message with fade transition -->
    <div v-if="props.goodbyeMessage" class="flex justify-center">
      <Transition name="fade-quick" mode="out-in" appear>
        <h1
          :key="props.goodbyeMessage"
          class="mt-15 text-25 text-ppGray-900 font-600 leading-none"
        >
          {{ props.goodbyeMessage }}
        </h1>
      </Transition>
    </div>
  </div>
</template>

<style scoped>
h1 {
  letter-spacing: -0.1rem;
}

/* Quick fade for goodbye message (0.5s) */
.fade-quick-enter-active,
.fade-quick-leave-active {
  transition: opacity 0.5s ease;
}
.fade-quick-enter-from,
.fade-quick-leave-to {
  opacity: 0;
}
.fade-quick-enter-to,
.fade-quick-leave-from {
  opacity: 1;
}
</style>
