// utils/plateProcessor.ts

import { z } from 'zod'

export interface Plate {
  id: string
  plate: string
  timestamp: number
  paymentStatus?: 'paid' | 'unpaid'
}

export interface PlateState {
  currentPlate: Plate | null
  queue: Plate[]
}

export function generatePlateId(): string {
  return `${Date.now()}-${Math.random().toString(16).slice(2)}`
}

export function isDuplicatePlate(state: PlateState, plate: string): boolean {
  return (
    state.currentPlate?.plate === plate
    || state.queue.some(item => item.plate === plate)
  )
}

export function addToQueue(state: PlateState, newPlate: Plate): PlateState {
  const queue = [...state.queue]

  if (isDuplicatePlate(state, newPlate.plate)) {
    // Discard duplicate
    return state
  }

  if (queue.length >= 1) {
    // Remove the oldest plate
    queue.shift()
  }

  queue.push(newPlate)

  return { ...state, queue }
}

export function processPlate(
  state: PlateState,
  newPlate: Plate | undefined,
  minDuration: number,
  maxDuration: number,
  now: number,
): { state: PlateState, action: 'display' | 'replace' | 'clear' | null } {
  if (newPlate) {
    if (isDuplicatePlate(state, newPlate.plate)) {
      return { state, action: null } // No action needed
    }

    if (!state.currentPlate) {
      // No current plate -> Display the new one immediately
      return {
        state: { ...state, currentPlate: { ...newPlate, timestamp: now } },
        action: 'display',
      }
    }
    else {
      const elapsed = now - state.currentPlate.timestamp

      if (elapsed < minDuration) {
        // Too soon to replace -> Add to queue
        return { state: addToQueue(state, newPlate), action: null }
      }
      else {
        // Replace the current plate
        return {
          state: { ...state, currentPlate: { ...newPlate, timestamp: now } },
          action: 'replace',
        }
      }
    }
  }
  else {
    if (state.currentPlate) {
      const elapsed = now - state.currentPlate.timestamp

      if (elapsed >= maxDuration) {
        // Too old -> Clear the plate
        return { state: { ...state, currentPlate: null }, action: 'clear' }
      }
      else if (elapsed >= minDuration && state.queue.length > 0) {
        const nextPlate = state.queue.shift()!
        return {
          state: {
            ...state,
            currentPlate: { ...nextPlate, timestamp: now },
            queue: state.queue,
          },
          action: 'replace',
        }
      }
    }
  }
  return { state, action: null }
}

// Define the schema for the "message" object
export const MessageSchema = z.object({
  plate: z.string(),
  country: z.string().min(1).max(3), // Assuming country codes are 1 to 3 characters long
  timestamp: z.number().int().positive(),
  direction: z.enum(['in', 'out', 'unknown']),
  paymentStatus: z.enum(['paid', 'unpaid']),
})

// Define the schema for the WebSocket data
export const WebSocketDataSchema = z
  .object({
    event_type: z.string(),
    message: MessageSchema,
    message_uuid: z.string().uuid(),
    action: z.string(),
    kiosk_uuid: z.string(),
  })
  .passthrough() // Allows unexpected fields without validation errors
