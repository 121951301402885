<script setup lang="ts">
defineOptions({
  name: 'PlatePlain',
})
const props = defineProps<{ plate?: string }>()

// Dynamically calculate font spacing based on plate length
// const dynamicFontSpacing = computed(() => {
//   if (props.plate && props.plate.length <= 6)
//     return 'tracking-[0.15em]'
//   if (props.plate && props.plate.length <= 8)
//     return 'tracking-[0.1em]'
//   return 'tracking-[0em]'
// })
</script>

<template>
  <div v-if="props.plate" class="custom-shadow rounded-xl bg-ppGray-600 p-2.5 text-[7rem] text-ppGray-600 font-700 leading-[5rem]">
    <div class="ml-12 rounded-r-xl bg-white px-9 py-5">
      <p>
        {{ props.plate }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.custom-shadow {
  box-shadow: 10px 40px 40px 0px #00000040;
}
</style>
