<script lang="ts" setup>
import { computed, onUnmounted, reactive, ref, watch } from 'vue'
import { useWebSocket } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { z } from 'zod'
import type { Plate, PlateState } from '@/utils/plateProcessor'
import {
  WebSocketDataSchema,
  generatePlateId,
  processPlate,
} from '@/utils/plateProcessor'
import {
  logWebsocketConnectionStatusClosed,
  logWebsocketConnectionStatusConnecting,
  logwebsocketConnectionStatusOpen,
} from '@/utils/logger'
import { simulateWebSocketPlates } from '@/utils/tests/simulateWebSocketPlates'

// ========================================
// Component Meta
// ========================================
defineOptions({
  name: 'AppContentDynamicExitScreen',
})

const props = defineProps<{
  logo?: string
  areaName: string
  goodbyeMessage: { default: string, unpaid: string }
  notes?: string
}>()

// ========================================
// Environment & Debugging
// ========================================
const isDev = import.meta.env.DEV
const wsBaseUrl = import.meta.env.VITE_DISPLAY_DYNAMIC_PLATE_WSS_URL

// eslint-disable-next-line no-console
const debugLog = isDev ? (...args: any[]) => console.log(...args) : () => {}
const logTransitions = isDev
const logTimeouts = isDev

// ========================================
// Constants & Enums
// ========================================
const ANIMATION_DURATION_MS = 800
const MIN_DISPLAY_DURATION = 2000
const MAX_DISPLAY_DURATION = 8000

enum WebsocketStatus {
  CONNECTING = 'CONNECTING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

// ========================================
// Route & WebSocket Setup
// ========================================
const route = useRoute()
const clientUuid = route.query.client_uuid
if (!clientUuid) {
  throw new Error('client_uuid is required')
}

const wsUrl = computed(() => `${wsBaseUrl}?client_uuid=${clientUuid}`)
const { status: _status, data } = useWebSocket(wsUrl.value, {
  autoReconnect: true,
  immediate: true,
})

// ========================================
// State
// ========================================
const state = reactive<PlateState>({
  currentPlate: null,
  queue: [],
})

const goodbyeMessage = ref(props.goodbyeMessage.default)
const showPlate = ref(false)
const showQRCode = ref(true)
const showNote = ref(true)

// ========================================
// Timeouts Management
// ========================================
const timeouts = new Set<ReturnType<typeof setTimeout>>()
onUnmounted(() => {
  timeouts.forEach(clearTimeout)
})

function dynamicTimeout(name: string, callback: () => void, delay: number) {
  if (logTimeouts)
    debugLog(`Setting timeout [${name}] for ${delay}ms`)
  const timeout = setTimeout(() => {
    timeouts.delete(timeout)
    callback()
  }, delay)
  timeouts.add(timeout)
}

// ========================================
// Plate Processing Logic
// ========================================
let isProcessScheduled = false

function getAnimationDurationCSSFormat() {
  const durationInSeconds = ANIMATION_DURATION_MS / 1000
  return durationInSeconds > 0 ? `${durationInSeconds}s` : '0.15s'
}

function scheduleProcessCalls() {
  if (isProcessScheduled)
    return
  isProcessScheduled = true

  dynamicTimeout('minDurationCall', () => {
    processPlateHandler()
    isProcessScheduled = false
  }, MIN_DISPLAY_DURATION)

  dynamicTimeout('maxDurationCall', () => {
    processPlateHandler()
    isProcessScheduled = false
  }, MAX_DISPLAY_DURATION)
}

function processPlateHandler(newPlate?: Plate) {
  const now = Date.now()
  const result = processPlate(state, newPlate, MIN_DISPLAY_DURATION, MAX_DISPLAY_DURATION, now)

  state.currentPlate = result.state.currentPlate
  state.queue = result.state.queue

  switch (result.action) {
    case 'display':
      showPlate.value = true
      scheduleProcessCalls()
      break
    case 'replace':
      showPlate.value = false
      dynamicTimeout('replaceAnimation', () => {
        showPlate.value = true
        scheduleProcessCalls()
      }, ANIMATION_DURATION_MS)
      break
    case 'clear':
      showPlate.value = false
      goodbyeMessage.value = props.goodbyeMessage.default
      showQRCode.value = true
      showNote.value = true
      dynamicTimeout('clearAnimation', () => {
        if (state.queue.length > 0) {
          processPlateHandler()
        }
        else {
          debugLog('Queue is empty. No plates to process.')
        }
      }, ANIMATION_DURATION_MS)
      break
  }
}

// ========================================
// Watchers & Handlers
// ========================================
watch(_status, (newStatus) => {
  if (newStatus === WebsocketStatus.CLOSED)
    logWebsocketConnectionStatusClosed()
  if (newStatus === WebsocketStatus.OPEN)
    logwebsocketConnectionStatusOpen()
  if (newStatus === WebsocketStatus.CONNECTING)
    logWebsocketConnectionStatusConnecting()
}, { immediate: true })

watch(data, (newData) => {
  if (!newData)
    return

  try {
    const parsedData = WebSocketDataSchema.parse(JSON.parse(newData))
    debugLog('Validated WebSocket Data:', parsedData)

    const plate = parsedData.message.plate
    const newPlate: Plate = {
      id: generatePlateId(),
      plate,
      timestamp: Date.now(),
      paymentStatus: parsedData.message.paymentStatus,
    }

    showQRCode.value = newPlate.paymentStatus !== 'paid'
    showNote.value = newPlate.paymentStatus !== 'paid'
    goodbyeMessage.value = newPlate.paymentStatus === 'paid'
      ? props.goodbyeMessage.default
      : props.goodbyeMessage.unpaid

    processPlateHandler(newPlate)
  }
  catch (err) {
    if (err instanceof z.ZodError) {
      console.error('Zod Validation Errors:', err.errors)
    }
    else {
      console.error('Unexpected Error:', err)
    }
  }
})

// ========================================
// In development mode, we simulate incoming WebSocket messages by periodically invoking `simulateWebSocketPlates`.
// Each simulated plate includes a payment status, which we then use to determine whether to show the QR code,
// display the note, and update the goodbye message. After adjusting these state variables, we pass the new plate
// object into the `processPlateHandler` function, ensuring the UI responds as if it had received real-time data from the server.
// ========================================
if (isDev) {
  simulateWebSocketPlates((emittedPlate) => {
    const newPlate: Plate = {
      id: `${emittedPlate.id}RandomSuffix`,
      plate: emittedPlate.plate,
      timestamp: emittedPlate.timestamp,
      paymentStatus: emittedPlate.paymentStatus,
    }

    if (newPlate.paymentStatus) {
      showQRCode.value = newPlate.paymentStatus !== 'paid'
      showNote.value = newPlate.paymentStatus !== 'paid'
      goodbyeMessage.value = newPlate.paymentStatus === 'paid'
        ? props.goodbyeMessage.default
        : props.goodbyeMessage.unpaid
    }

    processPlateHandler(newPlate)
  }, { includePaymentStatus: true, intervalRange: [1000, 9000] })
}
</script>

<template>
  <div>
    <AppContentTitle
      :area-name="props.areaName"
      :logo="props.logo"
      :goodbye-message="goodbyeMessage"
    />

    <!-- Plate Display -->
    <AppContentDynamicExitScreenPlateDisplay
      :show-plate="showPlate"
      :current-plate="state.currentPlate"
      :animation-duration="getAnimationDurationCSSFormat()"
      :log-transitions="logTransitions"
      :debug-log="debugLog"
    />

    <!-- QR code or Confirmation -->
    <AppContentDynamicExitScreenQrCodeOrConfirmation
      :show-qrcode="showQRCode"
      :animation-duration="getAnimationDurationCSSFormat()"
    />

    <!-- Notes -->
    <AppContentDynamicExitScreenNotesDisplay
      :show-note="showNote"
      :notes="props.notes"
    />
  </div>
</template>

<style scoped>
.bg-pattern {
  background: url('/dots_background.svg') repeat;
}
</style>
