<script lang="ts" setup>
defineOptions({
  name: 'AppContentNotes',
})
const props
  = defineProps<{
    carImage?: string
    notes?: string
  }>()
</script>

<template>
  <div class="bg-pattern flex items-center justify-center">
    <div class="white-gradient w-full rounded-xl px-15 py-15">
      <div class="flex items-center justify-between">
        <p class="text-10 text-gray-900 font-semibold leading-none">
          {{ props.notes }}
        </p>
        <img v-if="props.carImage" :src="props.carImage" class="" alt="car-image">
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-pattern {
 background: url('/dots_background.svg') repeat;
}

.white-gradient {
  background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.3) 100%);
}
</style>
