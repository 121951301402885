export const data = {
  areaName: 'Gera Arcaden',
  logo: '/gera_arcaden.avif',
  welcomeMessage: 'Willkommen in den',
  pricing: [
    {
      amount: 1,
      label: '1. & 2. Std.',
      from: true,
    },
    {
      amount: 1.50,
      label: 'ab 3. Std.',
      from: true,
    },
    {
      amount: 4.00,
      label: 'Tagesticket',
    },
  ],
}
