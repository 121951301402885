import { logEvent as _logEvent } from '@peter-park/vue-common-utils'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

import type { LogsInitConfiguration, StatusType } from '@datadog/browser-logs'
import type { RumInitConfiguration } from '@datadog/browser-rum'
import type { Context } from '@datadog/browser-core'

export const datadogConfig: LogsInitConfiguration = {
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  sessionSampleRate: 100,
  service: 'display-frontend',
  version: $APP_VERSION,
  env: import.meta.env.VITE_BUILD_STAGE,
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
}
export const datadogRumConfig: RumInitConfiguration = {
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  sessionSampleRate: 0,
  service: 'display-frontend',
  version: $APP_VERSION,
  env: import.meta.env.VITE_BUILD_STAGE,
  site: 'datadoghq.eu',
  applicationId: import.meta.env.VITE_DISPLAY_DD_APPLICATION_ID,
  sessionReplaySampleRate: 0,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
}

export interface LoggerSuperProperties extends Context {
  full_path: string
  client_uuid: string
  route_name: string
}
export function setSuperProperties(superProperties: LoggerSuperProperties) {
  if (import.meta.env.PROD) {
    datadogRum.setUser({
      id: superProperties.full_path.toString(),
      client_uuid: superProperties.client_uuid.toString(),
      route_name: superProperties.route_name.toString(),
    })
    datadogRum.onReady(() => {
      datadogRum.setGlobalContext(superProperties)
    })
    datadogLogs.onReady(() => {
      datadogLogs.setGlobalContext(superProperties)
    })
  }
  else {
    // eslint-disable-next-line no-console
    console.log('setSuperProperties', superProperties)
  }
}

/**
 * Internal enum with messages to log for each event
 */
enum LoggerEventsMessages {
  initApp = 'init Application',
  // websocket connections
  websocketConnectionFailed = 'Websocket Connection: Failed',
  websocketConnectionStatusConnecting = 'Websocket Connection: Status CONNECTING',
  websocketConnectionStatusOpen = 'Websocket Connection: Status OPEN',
  websocketConnectionStatusClosed = 'Websocket Connection: Status CLOSED',
}

export function logEvent(message: LoggerEventsMessages, messageContext?: Record<string, unknown>, statusType?: StatusType, error?: Error) {
  if (import.meta.env.PROD)
    _logEvent(message, messageContext, statusType, error, { mixpanel: false, datadog: true })

  else
    // eslint-disable-next-line no-console
    console.info(message, messageContext, statusType, error)
}

export function logInitApp(fullPath: string, status?: StatusType, error?: Error) {
  logEvent(LoggerEventsMessages.initApp, { fullPath }, status, error)
}

export function logWebsocketConnectionFailed() {
  logEvent(LoggerEventsMessages.websocketConnectionFailed, {}, 'error')
}

export function logWebsocketConnectionStatusConnecting() {
  logEvent(LoggerEventsMessages.websocketConnectionStatusConnecting, {}, 'info')
}
export function logwebsocketConnectionStatusOpen() {
  logEvent(LoggerEventsMessages.websocketConnectionStatusOpen, {}, 'info')
}

export function logWebsocketConnectionStatusClosed() {
  logEvent(LoggerEventsMessages.websocketConnectionStatusClosed, {}, 'warn')
}
