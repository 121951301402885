<script lang="ts" setup>
import { computed, onUnmounted, reactive, ref, watch } from 'vue'
import { useWebSocket } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { z } from 'zod'
import type {
  Plate,
  PlateState,
} from '@/utils/plateProcessor'
import {
  WebSocketDataSchema,
  generatePlateId,
  processPlate,
} from '@/utils/plateProcessor' // Adjust the import path
import {
  logWebsocketConnectionStatusClosed,
  logWebsocketConnectionStatusConnecting,
  logwebsocketConnectionStatusOpen,
} from '@/utils/logger'

// Define component options
defineOptions({
  name: 'AppContentDynamicPlateV2',
})

// Define component props
const props = defineProps<{
  logo?: string
  areaName: string
  welcomeMessage?: string
  carImage?: string
  notes?: string
  pricing: {
    label: string
    amount: number
    from?: boolean
  }[]
}>()

// Determine if the environment is development
const isDev = import.meta.env.DEV

// Debug logging function for development
const debugLog = isDev
  ? (...args: any[]) => console.log(...args) // eslint-disable-line no-console
  : () => {} // No-op in production

// Toggles for logging transitions and timeouts
const logTransitions = isDev
const logTimeouts = isDev

// WebSocket base URL from environment variables
const wsBaseUrl = import.meta.env.VITE_DISPLAY_DYNAMIC_PLATE_WSS_URL

// Get client UUID from route query parameters
const route = useRoute()
const clientUuid = route.query.client_uuid
if (!clientUuid) {
  throw new Error('client_uuid is required')
}

// Compute WebSocket URL with client UUID
const wsUrl = computed(() => `${wsBaseUrl}?client_uuid=${clientUuid}`)

// const pingMessage = {
//   action: 'ping',
//   message: clientUuid,
// }
// Initialize WebSocket connection
const { status: _status, data } = useWebSocket(wsUrl.value, {
  autoReconnect: true,
  immediate: true,
  // heartbeat: {
  //   message: JSON.stringify(pingMessage),
  //   interval: 5000,
  // },
})

// Enum for WebSocket connection status
enum WebsocketStatus {
  CONNECTING = 'CONNECTING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

// Watch WebSocket status and log changes
watch(
  _status,
  (newStatus) => {
    if (newStatus === WebsocketStatus.CLOSED) {
      logWebsocketConnectionStatusClosed()
    }
    if (newStatus === WebsocketStatus.OPEN) {
      logwebsocketConnectionStatusOpen()
    }
    if (newStatus === WebsocketStatus.CONNECTING) {
      logWebsocketConnectionStatusConnecting()
    }
  },
  {
    immediate: true,
  },
)

// Constants for plate display durations
const minDuration = 2000 // Minimum display time (ms)
const maxDuration = 8000 // Maximum display time (ms)

// Reactive state for plate processing
const state = reactive<PlateState>({
  currentPlate: null,
  queue: [],
})

// Boolean for animations
const showPlate = ref(false)

// Animation duration in milliseconds and CSS format
const animationDurationInMs = 150

function getAnimationDurationCSSFormat() {
  const duration = animationDurationInMs / 1000
  if (duration <= 0) {
    console.error('Invalid animation duration:', animationDurationInMs)
    return '0.15s' // Fallback
  }
  return `${duration}s`
}

// Set to store active timeouts
const timeouts = new Set<ReturnType<typeof setTimeout>>()

// Flag to prevent multiple scheduled process calls
let isProcessScheduled = false

// Set up function to handle dynamic timeouts
function dynamicTimeout(name: string, callback: () => void, delay: number) {
  if (logTimeouts) {
    debugLog(`Setting timeout for ${name} with delay: ${delay}ms`)
  }
  const timeout = setTimeout(() => {
    timeouts.delete(timeout)
    callback()
  }, delay)
  timeouts.add(timeout)
}

// Clear all timeouts on component unmount
onUnmounted(() => {
  timeouts.forEach(clearTimeout)
})

// Function to schedule process calls for minDuration and maxDuration
function scheduleProcessCalls() {
  if (!isProcessScheduled) {
    isProcessScheduled = true
    dynamicTimeout('minDurationCall', () => {
      processPlateHandler()
      isProcessScheduled = false
    }, minDuration)
    dynamicTimeout('maxDurationCall', () => {
      processPlateHandler()
      isProcessScheduled = false
    }, maxDuration)
  }
}

// Function to handle plate processing
function processPlateHandler(newPlate?: Plate) {
  const now = Date.now()
  const result = processPlate(state, newPlate, minDuration, maxDuration, now)

  // Update state
  state.currentPlate = result.state.currentPlate
  state.queue = result.state.queue

  // Handle actions
  switch (result.action) {
    case 'display':
      showPlate.value = true
      scheduleProcessCalls()
      break
    case 'replace':
      showPlate.value = false // Start hide animation
      dynamicTimeout('replaceAnimation', () => {
        showPlate.value = true // Show new plate after animation
        scheduleProcessCalls()
      }, animationDurationInMs)
      break
    case 'clear':
      showPlate.value = false // Start hide animation
      dynamicTimeout('clearAnimation', () => {
        // If there's a plate in the queue, process it
        if (state.queue.length > 0) {
          processPlateHandler()
        }
        else {
          // No plates to process
          debugLog('Queue is empty. No plates to process.')
        }
      }, animationDurationInMs)
      break
    default:
      break
  }
}

// Watch for WebSocket data updates
watch(data, (newData) => {
  try {
    if (newData) {
      const parsedData = WebSocketDataSchema.parse(JSON.parse(newData))
      debugLog('Validated WebSocket Data:', parsedData)

      const plate = parsedData.message.plate

      // Generate a new plate object and process it
      const newPlate: Plate = {
        id: generatePlateId(),
        plate,
        timestamp: Date.now(), // Use current timestamp
      }
      processPlateHandler(newPlate)
    }
  }
  catch (err) {
    if (err instanceof z.ZodError) {
      console.error('Zod Validation Errors:', err.errors)
    }
    else {
      console.error('Unexpected Error:', err)
    }
  }
})
</script>

<template>
  <div>
    <AppContentTitle :area-name="props.areaName" :logo="props.logo" :welcome-message="props.welcomeMessage" />
    <div class="relative">
      <div class="absolute top-16 items-center justify-left px-15">
        <Transition
          name="fade"
          mode="out-in"
          :style="{ '--animation-duration': getAnimationDurationCSSFormat() }"
          @before-enter="logTransitions && debugLog('Before Enter Transition')"
          @enter="logTransitions && debugLog('Enter Transition')"
          @leave="logTransitions && debugLog('Leave Transition')"
        >
          <PlatePlain v-if="showPlate" :plate="state.currentPlate?.plate" />
        </Transition>
      </div>
    </div>
    <AppContentPriceContainer class="mt-70" :pricing="props.pricing" :compact="true" />
    <AppContentNotes v-if="props.carImage" :car-image="props.carImage" :notes="props.notes" />
  </div>
</template>

<style scoped>
/* Fade-in and fade-out animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity var(--animation-duration, 0.15s) ease, transform var(--animation-duration, 0.15s) ease;
}

.fade-enter-from {
  opacity: 0;
  transform: translateX(-150px);
}

.fade-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.fade-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.fade-leave-to {
  opacity: 0;
  transform: translateX(80px);
}
</style>
