import { createApp } from 'vue'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'
import { VueQueryPlugin } from '@tanstack/vue-query'

import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import App from './App.vue'
import router from './router'

import 'virtual:uno.css'
import '@unocss/reset/tailwind-compat.css'
import DefaultLayout from './layouts/DefaultLayout.vue'

import { datadogConfig, datadogRumConfig } from './utils/logger'

if (import.meta.env.PROD) {
  datadogLogs.init(datadogConfig)
  datadogRum.init(datadogRumConfig)
  datadogRum.startSessionReplayRecording()
}
const app = createApp(App)

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: !(!import.meta.env.DEV || import.meta.env.VITE_BUILD_STAGE === 'staging'), // only refetch on window focus in production
        retry: 3, // we retry each query a maximum of 3 times
        retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 5000), // double delay between each retry and never go above 5 seconds
      },
    },
  },
}
const i18n = createI18n({
  legacy: false,
  locale: navigator.language, // TODO: get from browser after we have other language as well
  fallbackLocale: {
    de: ['de-DE'],
    en: ['en-GB', 'en-US'],
    it: ['it-IT', 'it-CH'],
    fr: ['fr-FR', 'fr-CH'],
  },
  messages,
})
app.use(router)
app.use(i18n)
app.use(VueQueryPlugin, vueQueryPluginOptions)
app.component('DefaultLayout', DefaultLayout)
app.mount('#app')
